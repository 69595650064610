import { SelectChainCard } from '@/components/SelectChain/SelectChainCard';
import { flashbox, isProd } from '@/config';
import { useIsDanceboxRegistrationEnabledFlag } from '@/hooks/flags/useIsDanceboxRegistrationEnabledFlag';
import { useIsFlashboxRegistrationEnabledFlag } from '@/hooks/flags/useIsFlashboxRegistrationEnabledFlag';
import { useDedicatedChainKey } from '@/hooks/useDedicatedChainKey';
import { useRestakeChainKey } from '@/hooks/useRestakeChainKey';
import { Grid, Stack } from '@mantine/core';
import { Alert } from '../Alert';

export function SelectChainCards() {
  const dedicatedChainKey = useDedicatedChainKey();
  const restakeChainKey = useRestakeChainKey();

  const flashboxFlag = useIsFlashboxRegistrationEnabledFlag();
  const isFlashboxDisabled = !flashboxFlag.isLoading && !flashboxFlag.isEnabled;

  const danceboxFlag = useIsDanceboxRegistrationEnabledFlag();
  const isDanceboxDisabled = !danceboxFlag.isLoading && !danceboxFlag.isEnabled;

  return (
    <Stack gap={'md'} mt={30}>
      {isFlashboxDisabled && flashboxFlag.text && (
        <Alert>{flashboxFlag.text}</Alert>
      )}
      {isDanceboxDisabled && danceboxFlag.text && (
        <Alert>{danceboxFlag.text}</Alert>
      )}
      <Grid>
        <Grid.Col span={{ base: 12, lg: !isProd ? 4 : 6 }}>
          <SelectChainCard
            titleChainType="Snap"
            description="Perfect for quick, trial-based deployments, Snap Appchains are auto-deprecated after 48 hours - ideal for brief testing phases."
            isDisabled={isFlashboxDisabled}
            chainKey={flashbox.key}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: !isProd ? 4 : 6 }}>
          <SelectChainCard
            titleChainType="Dedicated"
            description="Ideal for teams needing a long-term test environment, this option provides a stable and enduring platform for your appchain development."
            isDisabled={isDanceboxDisabled}
            chainKey={dedicatedChainKey}
          />
        </Grid.Col>
        {!isProd && restakeChainKey && (
          <Grid.Col span={{ base: 12, lg: 4 }}>
            <SelectChainCard
              titleChainType="Restake"
              description="Ideal for teams needing a long-term test environment, this option provides a stable and enduring platform for your appchain development."
              chainKey={restakeChainKey}
            />
          </Grid.Col>
        )}
      </Grid>
    </Stack>
  );
}
