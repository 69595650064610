import { AppchainCard } from '@/components/AppchainCard';
import { ExplorerAlert } from '@/components/AppchainCard/Alerts/ExplorerAlert';
import { useSidebar } from '@/components/Sidebar/state/sidebar.hooks';
import { getChainConfig } from '@/config';
import type { Appchain } from '@/shared/db/entities/appchains';
import { Box } from '@mantine/core';
import { usePrevious } from '@mantine/hooks';
import { useManageAppchainState } from './ManageAppchain/state';
import {
  ManageAppchainTab,
  isXcmTab,
} from './ManageAppchain/state/ManageAppchain.constants';
import { HrmpChannels } from './XCM/HrmpChannels';

export interface Props {
  appchains: Appchain[];
}

export function Dashboard({ appchains }: Props) {
  const { tab } = useManageAppchainState();
  const previousTab = usePrevious(tab);
  const { sidebar } = useSidebar();
  const isSidebarOpen = !!sidebar;

  if (
    isSidebarOpen &&
    (isXcmTab(tab) ||
      (isXcmTab(previousTab) && tab === ManageAppchainTab.Proxy))
  ) {
    return <HrmpChannels />;
  }

  return (
    <>
      <Box component={'section'} pt={20}>
        {appchains?.map((appchain, i) => {
          const config = getChainConfig(appchain.chainKey);

          if (!config) {
            return null;
          }

          return (
            <Box key={appchain.paraId} mt={i && 40}>
              <AppchainCard appchain={appchain} config={config} />
              <ExplorerAlert appchain={appchain} />
            </Box>
          );
        })}
      </Box>
    </>
  );
}
