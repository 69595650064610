import { ChainKey, isLocal } from '@/config';
import { useMemo } from 'react';

export function useRestakeChainKey():
  | ChainKey.Stagelight
  | ChainKey.LocalStagelight
  | undefined {
  return useMemo(() => {
    if (isLocal) return ChainKey.LocalStagelight;

    return ChainKey.Stagelight;
  }, []);
}
